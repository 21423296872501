<template>
  <b-modal
    id="details-modal"
    size="lg"
    title="Media Details"
    cancel-title="Close"
    :ok-only="!isSelectable"
    :ok-title="isSelectable ? 'Select' : 'Close'"
    centered
    @ok="insertImage"
  >
    <template v-if="tempMedia">
      <b-container fluid>
        <b-form-row>
          <b-col cols="6">
            <img
              v-if="mediaType == 'image'"
              :src="handleImageUrl(tempMedia)"
              alt=""
              class="w-100 h-auto media-preview"
            />
            <embed
              v-else-if="mediaType == 'doc'"
              :src="handleImageUrl(tempMedia)"
              width="auto"
              height="400"
              class="media-preview"
              type="application/pdf"
            />
            <!-- <img
              src="/pdf.png"
              alt=""
              class="w-100 h-auto"
            /> -->
            <video
              v-else
              :src="handleImageUrl(tempMedia)"
              class="w-100 h-auto"
              controls
            ></video>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Media">
              <b-form-input
                disabled
                :value="handleImageUrl(tempMedia)"
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mb-2"
                @click="copyUrl(handleImageUrl(tempMedia))"
              >
                کپی کردن
              </b-button>
            </div>
            <b-form-group label="Upload Date" v-if="tempMedia.createDate">
              <b-form-input
                disabled
                :value="
                  new Date(tempMedia.createDate).toLocaleDateString('fa-IR')
                "
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.media-delete-modal
                variant="danger"
                size="sm"
                class="my-1"
              >
                Delete Media
              </b-button>
            </div>
          </b-col>
        </b-form-row>
      </b-container>
      <b-modal
        id="media-delete-modal"
        title="Delete a media"
        ok-variant="danger"
        ok-title="Yes"
        cancel-title="Dismiss"
        centered
        @ok="sendDeleteRequest"
      >
        <span>Are you sure you want to remove this media?</span>
      </b-modal>
    </template>
  </b-modal>
</template>

<script>
export default {
  emits: ["refetch", "insertVideo", "insertImage"],
  props: {
    tempMedia: {
      type: Object,
    },
    mediaType: {
      // image, video, doc
      type: String,
      required: false,
      default: "image",
    },
    isSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    insertImage() {
      if (this.isSelectable) {
        if (this.tempMedia.pictureUrl.endsWith("mp4")) {
          this.$emit("insertVideo", this.handleImageUrl(this.tempMedia));
        } else {
          this.$emit("insertImage", this.handleImageUrl(this.tempMedia));
        }
      } else {
        this.$bvModal.hide("details-modal");
      }
    },
    async sendDeleteRequest() {
      try {
        let _this = this;
        let deleteAMedia = new DeleteAMedia(_this);
        deleteAMedia.setRequestParam({
          pictureUrl: _this.tempMedia.pictureUrl,
        });
        await deleteAMedia.fetch((response) => {
          if (response.isSuccess) {
            _this.$emit("refetch");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Media Deleted Successfully",
              },
            });
            _this.$bvModal.hide("details-modal");
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    copyUrl(imageUrl) {
      if (navigator) {
        navigator.clipboard.writeText(imageUrl);
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: "Operation Was Successful",
            icon: "CheckIcon",
            variant: "success",
            text: "Link Copied Successfully",
          },
        });
      }
    },
    handleImageUrl(image) {
      return `${Helper.baseUrl}/${image.pictureUrl}`;
    },
  },
  components: {
    BModal,
    BContainer,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import { DeleteAMedia } from "@/libs/Api/Media";
import {
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
</script>
<style scoped>
.media-preview {
  height: 400px;
  max-height: 400px;
  object-fit: contain;
}
</style>
