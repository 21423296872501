<template>
  <b-row>
    <b-col cols="12">
      <b-form class="w-100" @submit.prevent>
        <b-form-group :label="placeholder" label-for="menu-picker">
          <v-select
            @search="handleSearch"
            id="menu-picker"
            :filterable="false"
            v-if="menuList"
            v-model="selectedMenu"
            :options="menuList"
            label="name"
            placeholder="Choose Menu"
          >
            <template #no-options>No Menu Available</template>
          </v-select>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>
<script>
import vSelect from "vue-select";
import { BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";
import { GetAllMenu } from "@/libs/Api/Menu";
export default {
  emits: ["getMenu"],
  props: {
    defaultMenuId: {
      type: [String, Number],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Choose Your Desired Menu",
    },
  },
  watch: {
    selectedMenu: {
      handler(val) {
        this.$emit("getMenu", val);
      },
    },
  },
  data() {
    return {
      menuList: null,
      searchCommand: null,
      timeout: null,
      selectedMenu: null,
    };
  },
  async mounted() {
    await this.getAllMenu();
    if (this.defaultMenuId) {
      let selectedMenu = this.menuList.find(
        (item) => item.menuId == this.defaultMenuId
      );
      if (selectedMenu) {
        this.selectedMenu = selectedMenu;
      }
    }
  },
  methods: {
    async getAllMenu() {
      try {
        let reqParams = {
          count: 0,
          pageNumber: 0,
        };
        if (this.searchCommand) {
          reqParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllMenu = new GetAllMenu(_this);
        getAllMenu.setRequestParamDataObj(reqParams);
        await getAllMenu.fetch((response) => {
          if (response.isSuccess) {
            _this.menuList = response.data.menus;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.searchCommand = searchValue;
        loadingFn(true);
        await this.getAllMenu();
        loadingFn(false);
      }, 300);
    },
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
